import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';

function init() {
	LogRocket.init('xv21vy/insta360-community-9duvm');
	Sentry.init({
		dsn: 'https://fa50f39ec0de4420bc51f08de716790b@sentry.io/1818597',
	});
}

function identifyUser(user) {
	const { id: userId, email, nickname } = user;

	LogRocket.identify(userId, {
		name: nickname,
		email,
	});
}

const AnalyticsUtil = {
	init,
	identifyUser,
};

export default AnalyticsUtil;
